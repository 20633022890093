import styled from 'styled-components';

const WeekHeader = styled.div`
  display: flex;
  width: 100%;
  background-color: white;
  flex-wrap: wrap;
`;

const WeekDivision = styled.div`
  width: ${({ grid }) => grid ? 'calc(100% / 4)' : '100%'};
  height: 60px;
  padding-bottom: 4px;
  border-bottom: ${({ active }) => active && '4px solid #159fc9'};
  > div {
    margin: 4px 2px 0px;
    background-color: ${({ active }) => active ? 'white' : 'rgb(237,237,237)'};
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    height: 100%;
    width: calc(100% - 4px);
    cursor: pointer;
  }  
  @media (max-width: 600px) {
    width: 50%;
    margin-top: 1px;
  }
`;

const FirstPart = styled.div`
  width: 55% !important;
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  @media(max-width: 650px) {
    width: 100% !important;
  }
  .fullSpan {
    width: 100%;
  }
  .imageWrapperClass {
    float: left;
    width: 100%;
  }
`;

const SecondPart = styled.div`
  width: 45% !important;
  padding: 0px 20px 0px 30px;
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  @media(max-width: 650px) {
    width: 100% !important;
    padding: 20px;
  }
  .fullSpan {
    width: 100%;
  }
  .imageWrapperClass {
    float: left;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    > img {
      max-width: 100%;
      max-height: 250px;
      margin: 10px 0px 15px;
      width: auto;
    }
    .behaviorImage {
      float: left;
      width: 100%;
      font-size: 14px;
      letter-spacing: 0px;
      color: rgb(153,153,153);
      width: 100%;
      word-break: break-all;
    }
  }
  .buttonWrapper {
    float: left;
    width: 100%;
    > div {
      display: inline-block;
      min-width: 50%;
      float: left;
    }
  }
`;

const DateDashboard = styled.div`
  display: inline-block !important;
  height: 46px;
  margin-top: 50px;
  width: 50%;
  @media(max-width: 650px) {
    width: 100% !important;
  }
  .flexable {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    > span {
      font-size: 14px;
      letter-spacing: 0px;
      line-height: 24px;
      color: rgb(51,51,51);
      font-family: Rubik-Bold;
      float: left;
      padding: 0px 20px;
      @media(max-width: 650px) {
        padding: 0px 20px 0px 0px;
      }
    }
    > input{
      background-color: #eee;
    }
  }
`;

const WeekBox = styled.div`
  width: calc(100% - 60px);
  float: left;
  display: block;
  background-color: white;
  box-shadow: rgba(0,0,0,0.18) 1px 1.732px 46px;
  margin: 30px;
  height: 100%;
  .wrapHeader {
    width: 100%;
    background-color: rgb(237,237,237);
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  padding-left: ${({ paddingLeft }) => paddingLeft ? paddingLeft : '30px'};
  float: left;
  margin: ${({ margin }) => margin && margin};
  @media(max-width: 620px) {
    margin-bottom: 20px;
  }
`;

const RepeatContainer = styled.div` 
  display: inline-block;
  float: right;
  margin-top: ${({marginTop}) => marginTop ? '22px' : '20px'};
  margin-right: ${({marginRight})=>marginRight && '20px'};
  width: ${({marginRight})=> marginRight && '50%'};
  > span {
    font-size: 14px;
    -webkit-letter-spacing: 0px;
    -moz-letter-spacing: 0px;
    -ms-letter-spacing: 0px;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgb(51,51,51);
    font-family: Rubik-Bold;
    margin-left: 10px;
  }
  > span: first-child{
    margin-left: 0px;
  }
`;
const Text = styled.div`
	width: 100%;
	display: flex;
	float: right;
	justify-content: flex-end;
	> p{
		font-size: 15px;
	}
  @media (max-width: 700px) {
  margin-left: 72%;
  }
`;
export { Text, RepeatContainer, WeekHeader, WeekDivision, FirstPart, DateDashboard, WeekBox, SecondPart, ButtonWrapper };
