/* eslint-disable no-console,no-undef, camelcase */
import React, { PureComponent } from 'react';
import PropTypes from "prop-types";
import BehaviorForm from "./BehaviorForm";
import { ChallengeTitle } from '../CreateChallenge/styles';
import { withTranslation } from 'react-i18next';

class BehaviorChallenge extends PureComponent {

  render() {
    const { role, createChallenge, createLibrary, createBehaviorLibrary, createChallengeById, 
      behaviorChallengeDetailsById, editLibrary, t} = this.props;
    return (
      <div>
        <ChallengeTitle padding="30px 30px 10px">{`${editLibrary ? t("Edit") : t("Create")} ${t("a Behavior")} ${createLibrary || editLibrary ? t('Library') : t('Challenge') }`}</ChallengeTitle>
        <BehaviorForm
          history={this.props.history}
          role={role}
          createChallenge={createChallenge}
          createBehaviorLibrary={createBehaviorLibrary}
          createLibrary={createLibrary}
          createChallengeById={createChallengeById}
          behaviorChallengeDetailsById={behaviorChallengeDetailsById}
          challengeId={(editLibrary || createChallengeById) && behaviorChallengeDetailsById.id}
          editLibrary={editLibrary}
        />
      </div>
    )
  }
}

BehaviorChallenge.propTypes = {
  history: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  createChallenge: PropTypes.func,
  createLibrary: PropTypes.bool,
  createBehaviorLibrary: PropTypes.func,
  createChallengeById: PropTypes.bool,
  getBehvaiorChallengeByIdAction: PropTypes.func,
  behaviorChallengeDetailsById: PropTypes.object,
  match: PropTypes.object.isRequired,
  editLibrary: PropTypes.bool,
  t: PropTypes.func
};

export default (withTranslation()(BehaviorChallenge));