import React, {Component} from 'react';
import {
  Button,
  ChallengeContainer,
  ChallengesSvgNew, ChallengesWrapper,
  ContentContainer,
  SectionWrapper,
  PencilIcon
} from "../ChallengesCreation/styles";
import {ImageUrl} from "../../utils/constants";
import { getBehaviorChallengeDetails } from "../../redux/actions";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Waiting from "../Waiting";
import isEmpty from 'lodash/isEmpty';
import { getPermissionStatus } from '../../utils/methods';
import { withTranslation } from 'react-i18next';
const behaviourData =[
  "Nutrition",
  "Fitness",
  "Health",
  "Stress" ,
  "Lifestyle",
  "Workplace",
  "running_challenges"
];
class BehaviourChallengeCategories extends Component {
  componentDidMount() {
    const{getBehaviorChallengeDetails} = this.props;
    getBehaviorChallengeDetails();
  }
  renderSection = (information) => (
    <SectionWrapper>
      <span>{information === 'running_challenges' ? this.props.t('Running') :  this.props.t(information)}</span>
      <i className="fas fa-info-circle information-icon" />
    </SectionWrapper>
  );
  renderBehaviorChallenges = (behaviourData) => {
    const launchStatus = (getPermissionStatus("Launch behavior library", this.props.userPermissions));
    const{ t } = this.props;
    return behaviourData.map((challenge, index) => (
      <ChallengeContainer key={index}>
        <img src={`${ImageUrl}/${challenge.image}`} alt={challenge.name} onClick={() => launchStatus 
          && this.props.showCreateBehavior(challenge.id)}/>
        {(getPermissionStatus("Update behavior library", this.props.userPermissions)) && <PencilIcon removeRight={true} 
          className="fas fa-pencil-alt" onClick={() => this.props.editChallengeLibrary(challenge.id)}/>}
        <ContentContainer>
          <div>
            <span>{challenge.name}</span>
            <ChallengesSvgNew width={challenge.event_type == 'Stress' ? 1 : 0}>
              <img src={`${ImageUrl}/${challenge.event_icon}`} alt={challenge.event_icon}/>
              <span>{t(challenge.event_type)}</span>
            </ChallengesSvgNew>
          </div>
          {launchStatus && <div>
            <Button onClick={() => launchStatus && this.props.showCreateBehavior(challenge.id)}>{this.props.t("launch this challenge")}</Button>
          </div>}
        </ContentContainer>
      </ChallengeContainer>
    ))};

  daysContainer = (heading, data, index) => (
    <div key={index}>
      {this.renderSection(heading)}
      {
        <ChallengesWrapper flex={1} margin={1}>
          {this.renderBehaviorChallenges(data)}
        </ChallengesWrapper>
      }
    </div>
  );
  
  render() {
    const{behaviourChallengeDetails } = this.props;
    if(isEmpty(behaviourChallengeDetails)){
      return <Waiting />;
    }
    return (
      <div>
        { behaviourData.map((data, index) => (
          behaviourChallengeDetails[`${data}`] && behaviourChallengeDetails[`${data}`].length > 0 ? <div key={`${data}-${index}`}>{this.daysContainer(`${data}`, 
            behaviourChallengeDetails[`${data}`], `${data}-1-${index}`)}</div>: null
        ))}
      </div>
    );
  }
}
BehaviourChallengeCategories.propTypes = {
  history: PropTypes.object,
  behaviourChallengeDetails: PropTypes.object,
  makeChallengeLive: PropTypes.func,
  showCreateBehavior: PropTypes.func,
  getBehaviorChallengeDetails: PropTypes.func,
  editChallengeLibrary: PropTypes.func,
  userPermissions: PropTypes.array,
  userId: PropTypes.number,
  t: PropTypes.func
};
const mapStateToProps = (state) => ({
  behaviourChallengeDetails: state.challenges.behaviourChallengeDetails,
});

const mapDispatchToProps = (dispatch) => ({
  getBehaviorChallengeDetails: () => dispatch(getBehaviorChallengeDetails())
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BehaviourChallengeCategories));